import {useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/remote-config";
import { FaPaperPlane } from "react-icons/fa"; // https://react-icons.github.io/
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Media from 'react-bootstrap/Media';
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ProfilePopover from './ProfilePopover';
import { Project, ProjectComment, User } from "./types";
import moment from 'moment';
import Linkify from 'react-linkify';


type CommentListProps = {
  projectId: string
}
function CommentList(props: CommentListProps) {
  const projectId  = props.projectId;
  const [comments, setComments] = useState<ProjectComment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [commentText, setCommentText] = useState<string>('');
  const [isCommentButtonEnabled, setIsCommentButtonEnabled] = useState<boolean>(false);
  const [shouldShowCommentForm, setShouldShowCommentForm] = useState<boolean>(true);

  let storedUser = localStorage.getItem('authUser');
  let defaultAuthUser: User | null = null;
  if (typeof storedUser === 'string') {
    defaultAuthUser = JSON.parse(storedUser);
  }

  useEffect(() => {
    const fetchComments = async (projectIdToFetch: string) => {
      let db = firebase.firestore();
      await db.collection("project_comments").where("project.id", "==", projectIdToFetch).orderBy("createdTime", "desc").onSnapshot(
        (commentsSnapshot) => {
          var commentsList: ProjectComment[] = [];
          commentsSnapshot.forEach((commentDoc) => {
            commentsList.push(commentDoc.data() as ProjectComment);
          });
          setComments(commentsList);
          setIsLoading(false);
        },
        function (error) {
          console.log("Error when fetching comments", error);
          setIsLoading(false);
        }
      );
    }
    const fetchRemoteConfigCommentForm = async() => {
      const remoteConfig = firebase.remoteConfig();
      remoteConfig.settings.minimumFetchIntervalMillis = 3_600_000;
      remoteConfig.defaultConfig = {
        "web_allow_comment_creation": true
      };
      await remoteConfig.fetchAndActivate();
      const shouldShowCommentFormRc = remoteConfig.getValue("web_allow_comment_creation");
      console.log("RC fetched value: Should show comment form?", shouldShowCommentFormRc.asBoolean(), "Config from:", shouldShowCommentFormRc.getSource());
      setShouldShowCommentForm(shouldShowCommentFormRc.asBoolean());
    }
    fetchComments(projectId);
    fetchRemoteConfigCommentForm();
  }, [projectId]);

  const handleCommentSubmit = async () => {
    const trimmedCommentText = commentText.trim();
    if (trimmedCommentText.length < 3) {
      console.log("Comment is too short");
      return;
    }
    let db = firebase.firestore();
    const projectDataFetch = await db.collection("projects").doc(projectId).get();
    const projectData = projectDataFetch.data() as Project;
    // Disable button
    setIsCommentButtonEnabled(false);
    var newCommentRef = db.collection("project_comments").doc();
    const commentData = {
      createdTime: Math.floor(Date.now() / 1000),
      text: trimmedCommentText,
      creator: defaultAuthUser,
      project: projectData,
      id: newCommentRef.id,
    } as ProjectComment;
    newCommentRef.set(commentData).then(() => {
      console.log("Comment created!");
      setCommentText('');
      comments.unshift(commentData);
      setComments(comments);
    }).catch((error) => {
      setIsCommentButtonEnabled(true);
      console.error("Error updating document: ", error);
    });
  };

  const handleTextChange = (event: any) => {
    const commentText = event.target.value;
    setCommentText(commentText);
    const commentLength = commentText.trim().length;
    setIsCommentButtonEnabled(commentLength >= 3 && commentLength < 2000);
  }

  var projectComments = null;
  let commentForm = <div />;
  if (shouldShowCommentForm && defaultAuthUser !== null) {
    commentForm = <div className="mt-4 d-flex">
          <Image className="my-2" src={defaultAuthUser.photoUrl} roundedCircle width={48} height={48} alt="Profile pic" />
          <Form.Control className="flex-grow-1 mx-2" as="textarea" placeholder="Leave a comment here..." value={commentText} onChange={handleTextChange} />
          <Button className="my-2 rounded-circle send-button" onClick={handleCommentSubmit} disabled={!isCommentButtonEnabled}><FaPaperPlane /></Button>
        </div>;
  }
  if (isLoading) {
    projectComments = (
      <div className="d-flex loading-container-small">
        <ReactLoading type="spinningBubbles" className="m-auto" color="#9f9f9f"/>
      </div>
    );
  } else if (comments && comments.length === 0) {
    projectComments = (<div className="text-center">
      <hr/>
      <p>No comments on this project yet 😭</p>
      {commentForm}
    </div>);
  } else if (comments && comments.length > 0) {
    projectComments = (<div>
      <h4 className="mt-4">Comments</h4>
      <ListGroup>
      {comments.map(projectComment => {
        return (<ListGroup.Item key={projectComment.id}>
          <Media>
            <OverlayTrigger placement="bottom" delay={400} overlay={<ProfilePopover user={projectComment.creator} />}>
              <Link to={"/profile/" + projectComment.creator.username}>
                <Image src={projectComment.creator.photoUrl} roundedCircle className="mr-3" width={54} height={54} alt="Profile pic" />
              </Link>
            </OverlayTrigger>
            <Media.Body>
              <OverlayTrigger placement="bottom" delay={400} overlay={<ProfilePopover user={projectComment.creator} />}>
                <Link to={"/profile/" + projectComment.creator.username}>
                  <span className="text-dark font-weight-bold">{projectComment.creator.displayName}</span> <span className="text-dark">{"@" + projectComment.creator.username}</span>
                </Link>
              </OverlayTrigger>
              <p className="mb-0 respect-new-lines">
                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}>
                  {projectComment.text}
                </Linkify>
              </p>
              <small className="text-muted mb-1">{moment(new Date(projectComment.createdTime * 1000)).fromNow()}</small>
            </Media.Body>
          </Media>
          </ListGroup.Item>);
      })}
    </ListGroup>
    {commentForm}
    </div>);
  }
  return projectComments;
}

export default CommentList;
