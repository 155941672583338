import {useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from 'formik';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactLoading from "react-loading";
import Container from 'react-bootstrap/Container';
import { User } from "./types";
import * as yup from 'yup';

type OnboardingProps = {
  onAuthUpdated: (userUid: string) => void
}
function Onboarding(props: OnboardingProps) {
  let { uid } = useParams<{ uid: string }>();
  const [user, setUser] = useState<User|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shouldDisableEmailEdit, setShouldDisableEmailEdit] = useState<boolean>(true);
  const [isDuplicateUsername, setIsDuplicateUsername] = useState<boolean>(false);
  const history = useHistory();
  let db = firebase.firestore();

  // TODO: handle scenario if user leaves onboarding flow
  useEffect(() => {
    const fetchUser = async (userId: string) => {
      let db = firebase.firestore();
      await db.collection("users").doc(userId).onSnapshot(
        (userSnapshot) => {
          let userData = userSnapshot.data() as User;
          console.log("found user", userData);
          setUser(userData);
          if (userData !== null && userData !== undefined) {
            setIsLoading(false);
            setShouldDisableEmailEdit(userData.email !== undefined && userData.email !== null && userData.email.length > 3);
          }
        }
      );
    }
    fetchUser(uid);
  }, [uid]);

  const schema = yup.object().shape({
    displayName: yup.string().min(3, 'Too short').max(50, 'Too long').required('Required'),
    email: yup.string().email().required('Required'),
    username: yup.string().min(3, 'Too short').max(25, 'Too long').required('Required'),
    userBio: yup.string().min(3, 'Too short').max(2000, 'Too long').optional(),
  });

  function onFormSubmit(values: any) {
    // Check if this username is taken
    db.collection("users").where("username", "==", values.username).get()
      .then(async (querySnapshot) => {
        console.log("query snapshot", querySnapshot.size);
        // we already create the user with the specified username, so check if the returned doc is the current user
        if (querySnapshot.size > 1 || (querySnapshot.size === 1 && (querySnapshot.docs[0].data() as User).uid !== uid)) {
          console.error("This username is already taken");
          setIsDuplicateUsername(true);
        } else {
          setIsDuplicateUsername(false);
          const updatedData: any = {
            displayName: values.displayName,
            email: values.email,
            username: values.username,
            biography: values.userBio,
          };
          console.log("Form submitted", updatedData);
          await db.collection("users").doc(uid).update(updatedData);
          firebase.analytics().logEvent('onboarding_complete', updatedData);
          const toastMessage = `Welcome to Imber, ${updatedData.displayName}. Start the magic by creating your first project!`;
          // Update this data on the App so that the nav bar is updated appropriately
          props.onAuthUpdated(uid);
          history.replace("/", {message: toastMessage});
        }
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  }

  var duplicateUsernameAlert = <div />;
  if (isDuplicateUsername) {
    duplicateUsernameAlert = <Alert variant="danger">Sorry, this username is already taken.</Alert>;
  }
  var content = null;
  if (isLoading) {
    content = (
      <div className="d-flex loading-container">
        <ReactLoading type="spinningBubbles" className="m-auto" color="#9f9f9f"/>
      </div>
    );
  } else if (user) {
    const userOnboardingForm = (
      <Formik
          validationSchema={schema}
          onSubmit={onFormSubmit}
          initialValues={{
            displayName: user.displayName || '',
            email: user.email || '',
            username: user.username || '',
            userBio: user.biography || '',
          }}
        >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
        }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="formDisplayName">
            <Form.Label>Name</Form.Label>
            <h6 className="text-muted"><small>We use real names on Imber.</small></h6>
            <Form.Control size="lg"
              type="name"
              name="displayName"
              value={values.displayName}
              onChange={handleChange}
              onBlur={(event: any) => {
                handleBlur(event);
                const trimmedValue = (event.target.value || '').trim();
                setFieldValue('displayName', trimmedValue);
              }}
              isValid={touched.displayName && !errors.displayName}
              isInvalid={touched.displayName && !!errors.displayName}
            />
            <Form.Control.Feedback type="invalid">
              Provide a valid display name (min 3 characters)
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              disabled={shouldDisableEmailEdit}
              value={values.email}
              onChange={handleChange}
              onBlur={(event: any) => {
                handleBlur(event);
                const trimmedValue = (event.target.value || '').trim();
                setFieldValue('email', trimmedValue);
              }}
              isValid={touched.email && !errors.email}
              isInvalid={touched.email && !!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              Provide a valid email address
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <h6 className="text-muted"><small>Choose a unique username! You won't be able to change your username once it's set.</small></h6>
            <InputGroup>
              <InputGroup.Prepend>
                <h4 className="mr-2">@</h4>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                name="username"
                value={values.username}
                onChange={handleChange}
                onBlur={(event: any) => {
                  handleBlur(event);
                  const trimmedValue = (event.target.value || '').trim();
                  setFieldValue('username', trimmedValue);
                }}
                isValid={touched.username && !errors.username}
                isInvalid={touched.username && !!errors.username}
              />
              <Form.Control.Feedback type="invalid">
                Usernames are between 3 and 15 characters
              </Form.Control.Feedback>
            </InputGroup>
            {duplicateUsernameAlert}
          </Form.Group>

          <Form.Group controlId="formUserBio">
            <Form.Label>Biography</Form.Label>
            <h6 className="text-muted"><small>Tell others what you're all about in your biography. You can add links if you'd like.</small></h6>
            <Form.Control as="textarea" rows={6}
              type="text"
              name="userBio"
              value={values.userBio}
              onChange={handleChange}
              onBlur={(event: any) => {
                handleBlur(event);
                const trimmedValue = (event.target.value || '').trim();
                setFieldValue('userBio', trimmedValue);
              }}
              isValid={touched.userBio && !errors.userBio}
              isInvalid={touched.userBio && !!errors.userBio}
            />
            <Form.Control.Feedback type="invalid">
              Provide a valid biography (min 3 characters)
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit" block>
            Complete profile
          </Button>
        </Form>
        )}
      </Formik>
    );
    content = userOnboardingForm;
  }

  return (
    <Container className="main-content mt-5">
      <h3>Welcome to Imber!</h3>
      {content}
    </Container>);
}

export default Onboarding;
