import Container from 'react-bootstrap/esm/Container';
import Media from 'react-bootstrap/Media';

function About() {

  return (
    <Container className="main-content">
      <h2>Let's build better personal software projects, together.</h2>
      <strong>You won't be a great software engineer unless you build something meaningful.</strong>
      <p>Imber lets you showcase what you've built and gives you automated + manual feedback on your projects. For now, we're focused on front-end projects you've created: <img width="25px" src="/android_icon.png" alt="Android icon" /> Android , <img width="25px" src="/ios_icon_filled.png" alt="iOS icon" /> iOS, and <img width="25px" src="/web_icon_transparent.png" alt="Web icon" /> web. A single person (you!) can create something of value for thousands of people. These personal side projects are the <strong>primary lever</strong> to advance your career in tech.</p>
      <p>Why build personal projects?</p>
      <ul>
        <li>It's the closest you get to "real experience" without actually working at a company</li>
        <li>You gain the skills to make you a better engineer in the real world</li>
        <li>It's cheap (and in many cases, free)</li>
        <li>It's extremely fun and fulfilling &#128640;</li>
      </ul>
      <hr />
      <h3 className="mb-3">Built by:</h3>
      <p>Hi, we're Alex and Rahul, founders of Imber and the Tech Career Growth community. Our goal is to accelerate your career in tech by offering our knowledge + perspective from 15+ years of combined engineering experience in Silicon Valley. Join us on <a href="https://www.linkedin.com/company/techcareergrowth/" target="_blank" rel="noreferrer">LinkedIn</a> and <a href="https://join.slack.com/t/techcareergrowth/shared_invite/zt-lt2tbjcn-LOAVIDuGPI~nkuc4woHDLg" target="_blank" rel="noreferrer">Slack</a>.</p>
      <p className="mb4">Imber is a manifestation of constant feedback we heard from community members: How can I get interviews at top tech companies? How can I feel confident as a developer?</p>
      <Media className="mb-3">
        <img
          width={190}
          height={190}
          className="mr-3 rounded-circle"
          src="alex.png"
          alt="Alex Chiou"
        />
        <Media.Body>
          <h5>Alex Chiou</h5>
          <p className="mb-1"><a href="https://www.linkedin.com/in/alexander-chiou/" target="_blank" rel="noreferrer">LinkedIn</a></p>
          <p>Android engineer at Robinhood + ex-tech lead at Instagram and Facebook. Side project enthusiast who builds free, ad-free, open source mobile apps in my spare time. 2 million+ downloads on Google Play with 20,000+ ratings. Writer about the random stuff I do: <a href="https://medium.com/@chioualexander" rel="noreferrer" target="_blank">https://medium.com/@chioualexander</a></p>
          <img src="/robinhood_logo.png"  width="50px" alt="Robinhood logo" className="mr-4" /> <img src="/fb_logo.png" width="50px" alt="Facebook logo" />
        </Media.Body>
      </Media>
      <br/>
      <Media className="mb-4">
        <img
          width={190}
          height={190}
          className="mr-3 rounded-circle"
          src="rahul.jpg"
          alt="Rahul Pandey"
        />
        <Media.Body>
          <h5>Rahul Pandey</h5>
          <p className="mb-1"><a href="https://www.linkedin.com/in/rpandey1234/" target="_blank" rel="noreferrer">LinkedIn</a></p>
          <p>Experienced Android tech lead, <a href="https://web.stanford.edu/class/cs194a/" target="_blank" rel="noreferrer">Stanford lecturer</a>, and <a href="https://www.youtube.com/rpandey1234" rel="noreferrer" target="_blank">YouTuber</a>. Currently an Android TL at Facebook Portal and writer/speaker about Android + tech careers. Subscribe to my channel: <a href="https://www.youtube.com/rpandey1234" rel="noreferrer" target="_blank">youtube.com/rpandey1234</a></p>
          <img src="/fb_logo.png"  width="50px" alt="Facebook logo" className="mr-4" /> <img src="/pinterest_logo.png" width="50px" alt="Pinterest logo" />
        </Media.Body>
      </Media>
    </Container>);
}

export default About;
