import {useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import { useLocation } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import CommentList from './CommentList';
import HeartComponent from './HeartComponent';
import ProfilePopover from './ProfilePopover';
import ProjectDropdown from './ProjectDropdown';
import { User, Project } from "./types";
import moment from 'moment';
import Linkify from 'react-linkify';

function ProjectComponent() {
  let storedUser = localStorage.getItem('authUser');
  let defaultAuthUser: User | null = null;
  if (typeof storedUser === 'string') {
    defaultAuthUser = JSON.parse(storedUser);
  }
  let { projectId } = useParams<{ projectId: string }>();
  const locationState = useLocation() as any;
  const [project, setProject] = useState<Project | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchProject = async (projectIdToFetch: string) => {
      let db = firebase.firestore();
      await db.collection("projects").doc(projectIdToFetch).onSnapshot(
        (projectSnapshot) => {
          let projectData = projectSnapshot.data() as Project;
          setProject(projectData);
          setIsLoading(false);
          if (projectData) {
            firebase.analytics().logEvent('project_loaded', {project_name: projectData.name});
          }
        }
      );
    }
    fetchProject(projectId);
  }, [projectId]);

  var content = null;
  if (isLoading) {
    content = (
      <div className="d-flex loading-container">
        <ReactLoading type="spinningBubbles" className="m-auto" color="#9f9f9f"/>
      </div>
    );
  } else if (project) {
    var hasAtLeastOneLink = false;
    let projectLinks = <div />;
    let gPlayListItem = <div />;
    if (project.googlePlayStoreLink) {
      gPlayListItem = <Button variant="light" className="mb-2 link-width" href={project.googlePlayStoreLink} target="blank"><Image src="/google_play_store_logo.png" width="32px"/>  Google Play Store</Button>
      hasAtLeastOneLink = true;
    }
    let appStoreListItem = <div />;
    if (project.appleAppStoreLink) {
      appStoreListItem = <Button variant="light" className="mb-2 link-width" href={project.appleAppStoreLink} target="blank"><Image src="/apple_app_store_logo.png" width="32px"/>  Apple App Store</Button>;
      hasAtLeastOneLink = true;
    }
    let webLinkListItem = <div />;
    if (project.websiteLink) {
      webLinkListItem = <Button variant="light" className="mb-2 link-width" href={project.websiteLink} target="blank"><Image src="/web_icon.png" width="32px"/>  Website</Button>;
      hasAtLeastOneLink = true;
    }
    let githubListItem = <div />;
    if (project.gitHubLink) {
      githubListItem = <Button variant="light" className="mb-2 link-width" href={project.gitHubLink} target="blank"><Image src="/github_logo_fill.png" width="32px"/>  Github</Button>;
      hasAtLeastOneLink = true;
    }

    projectLinks = hasAtLeastOneLink ? (<div>
        <h4>Links</h4>
        {gPlayListItem}
        {appStoreListItem}
        {webLinkListItem}
        {githubListItem}
    </div>) : <div />;

    var projectImage = <div />;
    if (project.logoUrl) {
      projectImage = <Image src={project.logoUrl} width="200px" className="mr-3 responsive-image project-logo-corner" alt="Project logo" />;
    }
    let projectOptions = (<div className="float-right">
        <ProjectDropdown project={project} selfUsername={defaultAuthUser && defaultAuthUser.username} />
      </div>);
    content = (
      <div className="shadow-sm p-3 mb-3 bg-white rounded">
        {projectOptions}
        <Media>
          {projectImage}
          <Media.Body>
            <Media className="mb-3">
                <OverlayTrigger placement="bottom" delay={400} overlay={<ProfilePopover user={project.creator} />}>
                  <Link to={"/profile/" + project.creator.username}>
                    <Image src={project.creator.photoUrl} roundedCircle className="mr-2" width={48} height={48} alt="Profile pic" />
                  </Link>
                </OverlayTrigger>
                <Media.Body className="my-auto">
                  <span>
                    <OverlayTrigger placement="bottom" delay={400} overlay={<ProfilePopover user={project.creator} />}>
                      <a className="text-dark font-weight-bold" href={"/profile/" + project.creator.username}>{project.creator.displayName}</a>
                      {/* <button>hi</button> */}
                    </OverlayTrigger>
                  </span><br/>
                  <span>
                    <OverlayTrigger placement="bottom" delay={400} overlay={<ProfilePopover user={project.creator} />}>
                      <a className="text-muted" href={"/profile/" + project.creator.username}>{"@" + project.creator.username}</a>
                    </OverlayTrigger>
                  </span>
                </Media.Body>
            </Media>
            <h2>
              <span>{project.name + ' '}</span>
              <span className="center-badge">
                <Badge pill variant="info">{project.isInternal ? 'Internal' : ''}</Badge>
                </span>
            </h2>
            <span className="respect-new-lines">
              <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}>
                {project.description}
              </Linkify>
              </span>
            <br/>
            <small className="text-muted">{moment(new Date(project.createdTime * 1000)).fromNow()}</small>
            <br/><br/>
            <div className="mb-3"><HeartComponent project={project} selfUserId={defaultAuthUser && defaultAuthUser.uid} /></div>
            {projectLinks}
          </Media.Body>
        </Media>
        <CommentList projectId={projectId} />
      </div>
    );
  } else {
    content = <p>Error: could not find project with id <b>{projectId}</b></p>;
  }
  const flashMessage = locationState?.state?.message;
  var flashMessageLayout = <div />;
  if (flashMessage) {
    flashMessageLayout = <Alert variant="info">{flashMessage}</Alert>;
  }
  return (
    <Container className="main-content">
      {flashMessageLayout}
      {content}
    </Container>);
}

export default ProjectComponent;
