import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

import { firebaseConfig } from './firebase-config';

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    app.analytics();
  }
}

export default Firebase;
