export const firebaseConfig = {
    apiKey: "AIzaSyDwz1bOS9vdtxJGMPhU-mX_SppSKSRVrrY",
    authDomain: "imberapp.com",
    databaseURL: "https://openhouse-948ad.firebaseio.com",
    projectId: "openhouse-948ad",
    storageBucket: "openhouse-948ad.appspot.com",
    messagingSenderId: "183985297440",
    appId: "1:183985297440:web:06d4409166187fb8570445",
    measurementId: "G-SBN9BV8WZF"
  };
