import {useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import { useLocation } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import ReactLoading from "react-loading";
import { Project, User } from "./types.d"
import ProjectList from './ProjectList';

function Home() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selfUserId, setSelfUserId] = useState<string | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const locationState = useLocation() as any;
  window.history.replaceState(null, ''); // Clear the state from location on refreshes

  useEffect(() => {
    const fetchProjects = async () => {
      let db = firebase.firestore();
      let projectsQuery;
      let storedUser = localStorage.getItem('authUser');
      let defaultAuthUser: User | null = null;
      if (typeof storedUser === 'string') {
        defaultAuthUser = JSON.parse(storedUser);
      }
      if (defaultAuthUser && defaultAuthUser.isEmployee) {
        projectsQuery = db.collection("projects").orderBy("createdTime", "desc");
      } else {
        projectsQuery = db.collection("projects").where("isInternal", "==", false).orderBy("createdTime", "desc");
      }
      projectsQuery.onSnapshot(
        (projectsSnapshot) => {
          var projectsData: Project[] = [];
          projectsSnapshot.forEach((projectDoc) => {
            projectsData.push(projectDoc.data() as Project);
          });
          console.log("projects", projectsData);
          setProjects(projectsData);
          setIsLoading(false);
          firebase.analytics().logEvent('home_loaded', {num_projects: projectsData.length});
        }
      );
    }

    fetchProjects();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("user", user);
        setSelfUserId(user.uid);
      }
    });
  }, []); // empty array means we only want to fetch data when the component mounts

  var header = null;
  if (isLoading) {
    header = (
      <div className="d-flex loading-container">
        <ReactLoading type="spinningBubbles" className="m-auto" color="#9f9f9f"/>
      </div>
    );
  }
  var projectList = ProjectList(projects, selfUserId, false);
  const flashMessage = locationState?.state?.message;
  var flashMessageLayout = <div />;
  if (flashMessage) {
    flashMessageLayout = <Alert variant="info">{flashMessage}</Alert>;
  }
  return (
    <Container className="main-content">
      {flashMessageLayout}
      {isLoading ? header : projectList}
    </Container>
    );
}

export default Home;
