import React from "react"
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Popover from 'react-bootstrap/Popover';
import { User } from "./types";

type ProfilePopoverProps = {
  user: User;
}
const ProfilePopover = React.forwardRef((props: ProfilePopoverProps, ref: any) => {
  return (
    <Popover id="popover-basic" ref={ref} {...props} className="shadow-sm imber-popover">
      <Popover.Content>
        <Container className="mt-2">
        <Row>
          <Col md={2} className="pl-0">
            <Link to={"/profile/" + props.user.username}>
              <Image src={props.user.photoUrl} roundedCircle className="mr-2" width={70} height={70} alt="Profile pic" />
            </Link>
          </Col>
          <Col md={10}>
            <h6><a className="text-dark font-weight-bold" href={"/profile/" + props.user.username}>{props.user.displayName}</a></h6>
            <p><a className="text-dark" href={"/profile/" + props.user.username}>@{props.user.username}</a></p>
            <p className="text-muted line-clamp respect-new-lines">{props.user.biography}</p>
          </Col>
        </Row>
        </Container>
      </Popover.Content>
    </Popover>
  );
});

export default ProfilePopover;
