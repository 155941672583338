import {useState, useEffect} from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { Formik, ErrorMessage } from 'formik';
import InputGroup from 'react-bootstrap/InputGroup';
import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/storage';
import { User, Project } from "./types"
import * as yup from 'yup';

type CreateProps = {
  selfUserId: string
}
function CreateProject(props: CreateProps) {
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
  const SIZE_LIMIT = 2 * 1024 * 1024;
  const history = useHistory();

  const { projectId } = useParams<{ projectId: string }>();
  console.log(`self user id ${props.selfUserId}, project id ${projectId}`);
  let storedUser = localStorage.getItem('authUser');
  let db = firebase.firestore();
  // Assume there is a signed in user if we got here
  let defaultAuthUser: User = JSON.parse(storedUser as string);

  const [project, setProject] = useState<Project | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(true);

  useEffect(() => {
    const fetchProject = async (projectIdToFetch: string) => {
      await db.collection("projects").doc(projectIdToFetch).onSnapshot(
        (projectSnapshot) => {
          let projectData = projectSnapshot.data() as Project;
          setProject(projectData);
          if (projectData.creator.uid !== props.selfUserId) {
            console.log("Current user is not allowed to modify this project");
            setIsAuthorized(false);
          }
          setIsLoading(false);
        }
      );
    }
    if (projectId) {
      fetchProject(projectId);
    } else {
      setProject(null);
      setIsLoading(false);
    }
  }, [db, props.selfUserId, projectId]);

  function updateOrCreateProject(projectData: any, newProjectRef: firebase.firestore.DocumentReference) {
    var toastMessage;
    if (projectId == null) {
      // create new project
      projectData.id = newProjectRef.id;
      newProjectRef.set(projectData);
      toastMessage = "Project created successfully";
      history.replace("/", {message: toastMessage});
    } else {
      // update the document; keep existing fields
      delete projectData.createdTime;
      db.collection("projects").doc(projectId).update(projectData);
      toastMessage = "Project updated successfully";
      history.replace("/project/" + projectId, {message: toastMessage});
    }
    firebase.analytics().logEvent('project_created');
  }

  // TODO: use better type for param
  function onFormSubmit(values: any) {
    if (defaultAuthUser.isBanned) {
      // User cannot create the project since they are banned
      history.replace("/profile/" + defaultAuthUser.username, {message: 'We are taking a look at your submission'});
      return;
    }
    const updatedData: any = {
      creator: defaultAuthUser,
      createdTime: Math.floor(Date.now() / 1000),
      name: values.projectName,
      description: values.projectDescription,
      websiteLink: values.websiteLink || "",
      gitHubLink: values.githubLink || "",
      appleAppStoreLink: values.appleLink || "",
      googlePlayStoreLink: values.gPlayLink || "",
      isInternal: values.isInternal || false,
    };
    console.log("project logo", values.projectLogo);
    const newProjectRef = db.collection("projects").doc();
    const projectIdToUpdate = projectId || newProjectRef.id;
    // Disable button
    setIsFormSubmitted(true);
    if (values.projectLogo !== null) {
      console.log("Upload project logo to Firebase storage");
      var storageRef = firebase.storage().ref();
      var projectIdStorageRef = storageRef.child(`project-${projectIdToUpdate}/logo`);
      const uploadTask = projectIdStorageRef.put(values.projectLogo);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          console.error("error uploading image", error);
          setIsFormSubmitted(false);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('Uploaded file available at', downloadURL);
            updatedData.logoUrl = downloadURL;
            updateOrCreateProject(updatedData, newProjectRef);
          });
        }
      );
    } else {
      updateOrCreateProject(updatedData, newProjectRef);
    }
  }

  const schema = yup.object().shape({
    projectName: yup.string().min(3, 'Too short').max(50, 'Too long').required('Required'),
    projectLogo: yup.mixed()
                  .test('fileSize', 'File size is too large', value => (value === null) || value.size <= SIZE_LIMIT)
                  .test('fileType', 'Unsupported file format', value => (value === null) || SUPPORTED_FORMATS.includes(value.type))
                  .test('fileDimensions', 'Image is not square', async function(value) {
                    return new Promise((resolve, reject) => {
                      if (value === null) {
                        // Since logo is optional, we need allow nulls
                        resolve(true);
                      }
                      const selectedImageFile = document.createElement('img');
                      selectedImageFile.src = window.URL.createObjectURL(value);
                      selectedImageFile.onload = function() {
                        console.log(`image width is ${selectedImageFile.width} and height is ${selectedImageFile.height}`);
                        resolve(selectedImageFile.width === selectedImageFile.height);
                      }
                    });
                  }),
    projectDescription: yup.string().min(3, 'Too short').max(2000, 'Too long').required('Required'),
    gPlayLink: yup.string().url('Must be a URL').matches(/play.google.com/, 'Must be link to Play Store').optional(),
    websiteLink: yup.string().url('Must be a URL').matches(/https/).optional(),
    appleLink: yup.string().url('Must be a URL').matches(/apple.com/, 'Must be link to App Store').optional(),
    githubLink: yup.string().url('Must be a URL').matches(/github.com/, 'Must be link to Github').optional(),
    isInternal: yup.boolean().required(),
  });

  let projectForm = (
    <Formik
        validationSchema={schema}
        onSubmit={onFormSubmit}
        initialValues={{
          projectName: project?.name || '',
          projectLogo: null,
          projectDescription: project?.description || '',
          websiteLink: project?.websiteLink || '',
          githubLink: project?.gitHubLink || '',
          appleLink: project?.appleAppStoreLink || '',
          gPlayLink: project?.googlePlayStoreLink || '',
          isInternal: !!(project?.isInternal),
        }}
      >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group controlId="formProjectName">
          <Form.Label>Project name</Form.Label>
          <Form.Control
            type="text"
            name="projectName"
            value={values.projectName}
            onChange={handleChange}
            onBlur={(event: any) => {
              handleBlur(event);
              const trimmedValue = (event.target.value || '').trim();
              setFieldValue('projectName', trimmedValue);
            }}
            isValid={touched.projectName && !errors.projectName}
            isInvalid={touched.projectName && !!errors.projectName}
          />
          <Form.Control.Feedback type="invalid">
            Provide a valid project name (min 3 characters)
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formProjectDescription">
          <Form.Label>Project description</Form.Label>
          <Form.Control as="textarea" rows={3}
            type="text"
            name="projectDescription"
            value={values.projectDescription}
            onChange={handleChange}
            onBlur={(event: any) => {
              handleBlur(event);
              const trimmedValue = (event.target.value || '').trim();
              setFieldValue('projectDescription', trimmedValue);
            }}
            isValid={touched.projectDescription && !errors.projectDescription}
            isInvalid={touched.projectDescription && !!errors.projectDescription}
          />
          <Form.Control.Feedback type="invalid">
            Provide a valid description (min 3 characters)
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formProjectLogo">
          <Form.Label className="my-0">Logo</Form.Label>
          <div><small className="text-muted">Upload a square image less than 2 MB</small></div>
          {project?.logoUrl? <div><img src={project?.logoUrl} width="200px" className="responsive-image project-logo-corner" alt="Project logo" /><br /></div> : <div/>}
          <Form.Control
            type="file"
            accept="image/*"
            name="projectLogo"
            className="my-1"
            onChange={(event) => {
              if (event.currentTarget == null) {
                console.log("No file selected");
                setFieldValue('projectLogo', null);
                return;
              }
              const inputElement = event.currentTarget as HTMLInputElement;
              if (inputElement.files == null || inputElement.files.length === 0) {
                console.log("No file selected");
                setFieldValue('projectLogo', null);
                return;
              }
              const selectedImage = inputElement.files[0]
              console.log("selected image", selectedImage);
              setFieldValue('projectLogo', selectedImage);
            }}
            isInvalid={touched.projectLogo && !!errors.projectLogo}
          />
          <Form.Control.Feedback type="invalid">
            Provide a square image (.jpg or .png format) for the logo (&lt; 2 MB)
          </Form.Control.Feedback>
          <div className="display-flex invalid-feedback">
            <ErrorMessage name="projectLogo" />
          </div>
        </Form.Group>

        <Form.Group controlId="formProjectLinks">
          <Form.Label>Links</Form.Label>
          <div className="mb-3">
            <InputGroup>
              <InputGroup.Prepend>
                <Image src="/google_play_store_logo.png" width="38px" />
              </InputGroup.Prepend>
              <Form.Control placeholder="Google Play Store"
                type="url"
                name="gPlayLink"
                value={values.gPlayLink}
                onChange={handleChange}
                onBlur={(event: any) => {
                  handleBlur(event);
                  const trimmedValue = (event.target.value || '').trim();
                  setFieldValue('gPlayLink', trimmedValue);
                }}
                isValid={touched.gPlayLink && !errors.gPlayLink}
                isInvalid={touched.gPlayLink && !!errors.gPlayLink}
              />
            </InputGroup>
            <div className="display-flex invalid-feedback">
              <ErrorMessage name="gPlayLink" />
            </div>
          </div>
          <div className="mb-3">
            <InputGroup>
              <InputGroup.Prepend>
                <Image src="/apple_app_store_logo.png" width="38px" />
              </InputGroup.Prepend>
              <Form.Control placeholder="Apple App Store"
                type="url"
                name="appleLink"
                value={values.appleLink}
                onChange={handleChange}
                onBlur={(event: any) => {
                  handleBlur(event);
                  const trimmedValue = (event.target.value || '').trim();
                  setFieldValue('appleLink', trimmedValue);
                }}
                isValid={touched.appleLink && !errors.appleLink}
                isInvalid={touched.appleLink && !!errors.appleLink}
              />
            </InputGroup>
            <div className="display-flex invalid-feedback">
              <ErrorMessage name="appleLink" />
            </div>
          </div>
          <div className="mb-3">
            <InputGroup>
              <InputGroup.Prepend>
                <Image src="/web_icon.png" width="38px" />
              </InputGroup.Prepend>
              <Form.Control placeholder="Website"
                type="url"
                name="websiteLink"
                value={values.websiteLink}
                onChange={handleChange}
                onBlur={(event: any) => {
                  handleBlur(event);
                  const trimmedValue = (event.target.value || '').trim();
                  setFieldValue('websiteLink', trimmedValue);
                }}
                isValid={touched.websiteLink && !errors.websiteLink}
                isInvalid={touched.websiteLink && !!errors.websiteLink}
              />
            </InputGroup>
            <div className="display-flex invalid-feedback">
              <ErrorMessage name="websiteLink" />
            </div>
          </div>
          <div className="mb-3">
            <InputGroup>
              <InputGroup.Prepend>
                <Image src="/github_logo_fill.png" width="38px" />
              </InputGroup.Prepend>
              <Form.Control placeholder="Github"
                type="url"
                name="githubLink"
                value={values.githubLink}
                onChange={handleChange}
                onBlur={(event: any) => {
                  handleBlur(event);
                  const trimmedValue = (event.target.value || '').trim();
                  setFieldValue('githubLink', trimmedValue);
                }}
                isValid={touched.githubLink && !errors.githubLink}
                isInvalid={touched.githubLink && !!errors.githubLink}
              />
            </InputGroup>
            <div className="display-flex invalid-feedback">
              <ErrorMessage name="githubLink" />
            </div>
          </div>
          {defaultAuthUser.isEmployee?
            <Form.Group className="mb-3" controlId="formIsInternalCheckbox">
              <Form.Check type="checkbox" label="Is Internal?" name="isInternal" onChange={handleChange} checked={values.isInternal} />
            </Form.Group>
            : <div /> }
        </Form.Group>
        <Button variant="primary" type="submit" block disabled={isFormSubmitted}>
          {isFormSubmitted ? 'Submitting...' : (projectId? "Update" : "Create")}
        </Button>
      </Form>
      )}
    </Formik>
  );
  var content = null;
  if (isLoading) {
    content = (
      <div className="d-flex loading-container">
        <ReactLoading type="spinningBubbles" className="m-auto" color="#9f9f9f"/>
      </div>
    );
  } else {
    content = projectForm;
  }

  if (!isAuthorized) {
    return <Redirect
      to={{
        pathname: "/",
        state: { message: "You don't have permission to do that" }
      }}
    />;
  } else {
    return (
      <Container className="main-content">
        {content}
      </Container>);
  }
}

export default CreateProject;
