import {useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa"; // https://react-icons.github.io/
import firebase from "firebase/app";
import "firebase/firestore";
import Toast from 'react-bootstrap/Toast';
import { Project, User } from "./types";

type HeartProps = {
  project: Project,
  selfUserId: string | null
}
function HeartComponent(props: HeartProps) {
  const [showUnauthError, setShowUnauthError] = useState(false);
  let storedUser = localStorage.getItem('authUser');
  let defaultAuthUser: User | null = null;
  if (typeof storedUser === 'string') {
    defaultAuthUser = JSON.parse(storedUser);
  }

  function updateLikeOnProject(projectId: string, doesUserLikeProject: boolean, selfUserId: string|null) {
    if (defaultAuthUser === null) {
      setShowUnauthError(true);
      return;
    }
    let db = firebase.firestore();
    var updatedLikingUsers;
    if (doesUserLikeProject) {
      // remove this user from the array of liking user ids
      updatedLikingUsers = {likingUserIds: firebase.firestore.FieldValue.arrayRemove(selfUserId)}
    } else {
      // add this user from the array
      updatedLikingUsers = {likingUserIds: firebase.firestore.FieldValue.arrayUnion(selfUserId)}
    }
    console.log("updating liking users", updatedLikingUsers);
    db.collection("projects").doc(projectId).update(updatedLikingUsers);
  }

  var heartIcon = <FaRegHeart size="24px" className="a-icon" />;
  const doesSelfLikeProject = props.selfUserId != null && props.project.likingUserIds && props.project.likingUserIds.includes(props.selfUserId);
  if (doesSelfLikeProject) {
    heartIcon = <FaHeart size="24px" className="heart-icon heart-filled" />;
  }
  var heartsComponent = "0 likes";
  if (props.project.likingUserIds) {
    const likingText = (props.project.likingUserIds.length === 1) ? " like" : " likes";
    heartsComponent = props.project.likingUserIds.length.toString() + likingText;
  }

  return (<div>
    <small><span className="heart-icon" onClick={() => updateLikeOnProject(props.project.id, doesSelfLikeProject, props.selfUserId)}>{heartIcon}</span><span className="ml-2">{heartsComponent}</span></small>
    <Toast className="mt-2" onClose={() => setShowUnauthError(false)} show={showUnauthError} delay={3000} autohide>
      <Toast.Body>Please login to like projects</Toast.Body>
    </Toast>
  </div>);
}

export default HeartComponent;
