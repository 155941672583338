import "firebase/firestore";
import "firebase/auth";
import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';
import HeartComponent from './HeartComponent';
import ProfilePopover from './ProfilePopover';
import { FaRegCommentAlt } from "react-icons/fa"; // https://react-icons.github.io/
import { Project } from "./types"
import moment from 'moment';
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";

function ProjectList(projects: Project[], selfUserId: string | null, isProfilePage: boolean) {
  if (projects.length === 0) {
    return (
      <div className="card shadow-sm p-3 text-center mb-4">
        {/* sad face */}
        <span>😭</span>
        <p>Nothing to see here</p>
      </div>
    )
  }
  var content = projects.map((project) => {
    const hasLinks = project.googlePlayStoreLink || project.appleAppStoreLink || project.websiteLink || project.gitHubLink;
    var profileComponent = isProfilePage ? null :
      (
        <Media className="mb-2 clickable-card-elem">
          <OverlayTrigger placement="bottom" delay={400} overlay={<ProfilePopover user={project.creator} />}>
            <Link to={"/profile/" + project.creator.username}>
              <Image src={project.creator.photoUrl} roundedCircle className="mr-2" width={48} height={48} alt="Profile pic" />
            </Link>
          </OverlayTrigger>
          <div className="my-auto">
            <OverlayTrigger placement="bottom" delay={400} overlay={<ProfilePopover user={project.creator} />}>
              <span><a className="text-dark font-weight-bold" href={"/profile/" + project.creator.username}>{project.creator.displayName}</a></span>
            </OverlayTrigger><br/>
            <OverlayTrigger placement="bottom" delay={400} overlay={<ProfilePopover user={project.creator} />}>
              <span><a className="text-muted" href={"/profile/" + project.creator.username}>{"@" + project.creator.username}</a></span>
            </OverlayTrigger>
          </div>
        </Media>
      )
    return (
      <div className="card shadow-sm p-3 mb-3" key={project.id}>
        <Media>
          {project.logoUrl ? <Image src={project.logoUrl || '/logo_placeholder.png'} width="200px" className="mr-3 responsive-image project-logo-corner" alt="Project logo" /> : <div />}
          <Media.Body>
            {profileComponent}
            <h3>
              <Link className="stretched-link text-dark text-decoration-none" to={"/project/" + project.id}>
              <span>{project.name + ' '}</span>
              <span className="center-badge">
                <Badge pill variant="info">{project.isInternal ? 'Internal' : ''}</Badge>
              </span>
              </Link>
            </h3>
            <div className="line-clamp respect-new-lines mb-3">{project.description}</div>
            <div className="d-flex justify-content-between">
              <div className="clickable-card-elem mr-3">
                <HeartComponent project={project} selfUserId={selfUserId} />
              </div>
              <div className="mr-2"><FaRegCommentAlt className="mr-1" size={24} /> {project.numComments}</div>
            </div>
            <div>
              {hasLinks ? (<span>Available on: </span>) : null}
              {project.googlePlayStoreLink ? <Image src="/android_icon.png" width="32px"/>: <span />}
              {project.appleAppStoreLink ? <Image src="/ios_icon_filled.png" width="32px"/>: <span />}
              {project.websiteLink ? <Image src="/web_icon_transparent.png" width="32px"/>: <span />}
              {project.gitHubLink ? <Image src="/github_logo_fill.png" width="32px"/>: <span />}
            </div>
            <small className="text-muted">{moment(new Date(project.createdTime * 1000)).fromNow()}</small>
          </Media.Body>
        </Media>
      </div>
    );
  });
  return <div>{content}</div>;
}

export default ProjectList;
