import { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Toast from 'react-bootstrap/Toast';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import 'bootstrap/dist/css/bootstrap.min.css';
import Profile from './Profile';
import { FaPlus } from "react-icons/fa"; // https://react-icons.github.io/
import CreateProject from './CreateProject';
import ProjectComponent from './ProjectComponent';
import About from './About';
import Footer from './Footer';
import Home  from './Home';
import Onboarding  from './Onboarding';
import { User } from "./types.d"

import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import 'firebaseui/dist/firebaseui.css'
import "firebase/auth";
import { Redirect } from "react-router";

function App() {
  let storedUser = localStorage.getItem('authUser');
  let defaultAuthUser: User|null = null;
  if (typeof storedUser === 'string') {
    defaultAuthUser = JSON.parse(storedUser);
  }
  const [currentUser, setCurrentUser] = useState<User|null>(defaultAuthUser);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [toastMessage, setToastMessage] = useState<string|null>(null);

  const handleClose = () => setShowLoginModal(false);
  const handleShow = () => setShowLoginModal(true);
  const modalLoaded = () => {
    console.log("Load login UI in modal");
    loginUi.start('#firebaseui-auth-container', {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          console.log("auth result user", authResult.user.uid);
          if (authResult.additionalUserInfo.isNewUser) {
            console.log("This is a new user, show onboarding flow", authResult.additionalUserInfo);
            // Navigate to onboarding flow
            window.location.assign("/onboarding/" + authResult.user.uid);
          } else if (authResult.user) {
            // We'll fetch user details from the onAuthStateChanged callback
            setShowLoginModal(false);
            console.log("signInSuccessWithAuthResult");
            setToastMessage("Welcome back to Imber, " + authResult.user.displayName);
          } else {
            console.error("No user found from success auth result");
          }
          return false;
        },
      },
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          fullLabel: 'Continue with Google',
          customParameters: {
            // Forces account selection even when one account is available.
            prompt: 'select_account'
          }
        },
        {
          provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          fullLabel: 'Continue with Facebook',
        }
      ],
      // Terms of service url.
      tosUrl: 'https://github.com/Gear61/Openhouse-Documents/blob/main/Terms%20and%20Conditions.md',
      // Privacy policy url.
      privacyPolicyUrl: 'https://github.com/Gear61/Openhouse-Documents/blob/main/Privacy%20Policy.md'
    });
  }

  let loginUi = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());

  const onAuthUpdated = async (userUid: string) => {
    let db = firebase.firestore();
    let userDocument = await db.collection("users").doc(userUid).get();
    if (userDocument.exists) {
      console.log("Signed in user: ", userDocument.data());
      setCurrentUser(userDocument.data() as User);
      localStorage.setItem('authUser', JSON.stringify(userDocument.data()));
    } else {
      console.warn("User account created, but cloud function hasn't created the user document yet");
      setCurrentUser(null);
    }
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      // https://www.robinwieruch.de/react-firebase-authorization-roles-permissions
      console.log("onAuthStateChanged user");
      if (user) {
        onAuthUpdated(user.uid);
      } else {
        console.log("user is logged out now");
        localStorage.removeItem('authUser');
        setCurrentUser(null);
      }
      // Clear any stale state from previous auth state
      setShowLoginAlert(false);
    });
  }, []);

  function signOut() {
    firebase.auth().signOut();
    localStorage.removeItem('authUser');
    setCurrentUser(null);
  }

  function checkCreateFlow() {
    if (currentUser === null) {
      console.log("No user is logged in");
      setShowLoginAlert(true);
    } else {
      window.location.assign("/create");
      setShowLoginAlert(false);
    }
  }

  const NavigationAuth = (user: User) => (
    <NavDropdown className="no-caret-dropdown" alignRight title={<Image src={user.photoUrl ?? ''} roundedCircle width="30" height="30"/>} id="dropdown-basic">
      <NavDropdown.Item href={"/profile/" + user.username}>Profile</NavDropdown.Item>
      <NavDropdown.Item href="/about">About</NavDropdown.Item>
      <NavDropdown.Item onClick={signOut}>Sign out</NavDropdown.Item>
    </NavDropdown>
  );
  const NavigationNonAuth = () => (
    <Nav.Link onClick={() => {
      handleShow();
    }}>Login</Nav.Link>
  );

  const NavUser = (props: any) => {
    return <div>{props.user ? <NavigationAuth {...props.user}/> : <NavigationNonAuth />}</div>;
  };
  const loginModal = (
    <Modal show={showLoginModal} onHide={handleClose} onEntered={modalLoaded}>
      <Modal.Header closeButton>
        <Modal.Title>Join the Imber community</Modal.Title>
      </Modal.Header>
      <Modal.Body className="loading-container-small">
        <p className="text-center">The best place to showcase your personal software projects.</p>
        <div id="firebaseui-auth-container"></div>
      </Modal.Body>
    </Modal>
  );

  var flashMessageLayout = <div />;
  if (showLoginAlert) {
    flashMessageLayout = <Alert variant="info">In order to create a project, join the Imber community.</Alert>;
  }
  var toastLayout = <Toast className="absolute-center-left" onClose={() => setToastMessage(null)} show={!!toastMessage} delay={3000} autohide>
      <Toast.Body className="my-auto">{toastMessage}</Toast.Body>
    </Toast>;
  return (
    <div>
      <BrowserRouter>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/onboarding/:uid" children={<Onboarding onAuthUpdated={onAuthUpdated}/>} />
          <Route>
            <Navbar className="shadow-sm">
            {/* sticky="top" */}
              <div className="container">
                <Navbar.Brand href="/">
                  <img
                    src="/logo.png"
                    width="30"
                    height="30"
                    className="d-inline-block mb-2"
                    alt="Imber logo"
                  />{' '}Imber
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Nav className="ml-auto">
                  <Nav.Link onClick={checkCreateFlow} className="icon-parent">
                    Create <FaPlus />
                  </Nav.Link>
                  <NavUser user={currentUser} />
                </Nav>
              </div>
            </Navbar>
            <Container className="outer-content">
              {loginModal}
              {flashMessageLayout}
              {toastLayout}
              <Switch>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/users">
                  <Users />
                </Route>
                <Route path="/create">
                  {currentUser === null ? <Redirect to={{
                    pathname: "/",
                    state: { message: "In order to create a project, join the Imber community." }
                  }} /> : <CreateProject selfUserId={currentUser.uid} />}
                </Route>
                <Route path="/profile/:username" children={<Profile />} />
                <Route path="/project/:projectId/edit">
                  {currentUser === null ? <Redirect to={{
                    pathname: `/`,
                    state: { message: "You must be logged in to do that." }
                  }}
                  /> : <CreateProject selfUserId={currentUser.uid} />}
                </Route>
                <Route path="/project/:projectId" children={<ProjectComponent />} />
                <Route exact path="/">
                  <Home />
                </Route>
              </Switch>
              <Footer />
            </Container>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

function Users() {
  return <h2>Users</h2>;
}

export default App;
