import {useState } from "react";
import { useHistory } from "react-router-dom";
import { FaCopy, FaRegCheckCircle, FaEllipsisV, FaExclamationTriangle, FaTrash, FaPen, FaShareAlt } from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import ListGroup from 'react-bootstrap/ListGroup';
import { FacebookShareButton, LinkedinShareButton, RedditShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon, RedditIcon, TwitterIcon } from "react-share";
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast';
import firebase from "firebase/app";
import "firebase/firestore";
import { Project, User } from "./types"

type ProjectDropdownProps = {
  selfUsername: string | null,
  project: Project
}
function ProjectDropdown(props: ProjectDropdownProps) {
  let storedUser = localStorage.getItem('authUser');
  let defaultAuthUser: User | null = null;
  if (typeof storedUser === 'string') {
    defaultAuthUser = JSON.parse(storedUser);
  }
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [toastContents, setToastContents] = useState<string|null>(null);
  let db = firebase.firestore();
  const projectUrl = window.location.href + "?utm_source=share&utm_medium=web";

  const handleClose = () => setShowDeleteModal(false);
  const handleShow = () => setShowDeleteModal(true);
  const handleDelete = async () => {
    // delete the project from firestore
    await db.collection("projects").doc(props.project.id).delete();
    console.log(`Project ${props.project.name} deleted`);
    history.replace("/profile/" + props.selfUsername, {message: "Project deleted successfully"});
  }
  const handleCloseReportModal = () => setShowReportModal(false);
  const handleShowReportModal = () => setShowReportModal(true);
  const handleReport = async (reason: string) => {
    console.log(`Reporting project with reason ${reason}`);
    db.collection("project_reports").add({
      createdTime: Math.floor(Date.now() / 1000),
      reason: reason,
      reportedProject: props.project,
      reportingUserDisplayName: defaultAuthUser?.displayName,
      reportingUserId: defaultAuthUser?.uid
    });
    setShowReportModal(false);
    setToastContents("Thank you for your report - we'll take a look.");
  }
  const handleCloseShareModal = () => setShowShareModal(false);
  const handleShowShareModal = () => setShowShareModal(true);
  const handleUrlCopied = () => {
    navigator.clipboard.writeText(projectUrl);
    setToastContents("Copied link to clipboard!");
  }

  let dropdown = <div />; // if there's no signed in user, no drop down
  let signedInUserIsOwner = props.project.creator.username === props.selfUsername;
  if (defaultAuthUser !== null) {
    let dropdownOptions = (
      <Dropdown.Menu>
        <Dropdown.Item onClick={handleShowReportModal}><FaExclamationTriangle /> Report</Dropdown.Item>
      </Dropdown.Menu>
    );
    if (signedInUserIsOwner) {
      dropdownOptions = (
      <Dropdown.Menu>
          <Dropdown.Item onClick={handleShow}><FaTrash /> Delete</Dropdown.Item>
        </Dropdown.Menu>
      );
    }
    dropdown = (
      <Dropdown className="d-inline">
        <Dropdown.Toggle variant="link" id="project-options" className="no-caret-dropdown">
          <FaEllipsisV className="text-muted" size="20px"/>
        </Dropdown.Toggle>
        {dropdownOptions}
      </Dropdown>
    );
  }

  let maybeShareButton = <span />;
  if (signedInUserIsOwner) {
    maybeShareButton = (
    <Button href={"/project/" + props.project.id + "/edit"} variant="link">
      <FaPen className="text-muted" size="20px" />
    </Button>);
  }

  const reportModal = (
    <Modal show={showReportModal} onHide={handleCloseReportModal}>
      <Modal.Header closeButton>
        <Modal.Title>Choose Report Reason</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup variant="flush" className="pointer">
          <ListGroup.Item role="button" onClick={() => handleReport("project_is_spam")}>The project is spam</ListGroup.Item>
          <ListGroup.Item role="button" onClick={() => handleReport("not_a_side_project")}>The project is not a side project</ListGroup.Item>
          <ListGroup.Item role="button" onClick={() => handleReport("project_is_duplicate")}>The project is a duplicate</ListGroup.Item>
          <ListGroup.Item role="button" onClick={() => handleReport("project_is_malicious")}>The project is malicious (malware, phishing, etc)</ListGroup.Item>
          <ListGroup.Item role="button" onClick={() => handleReport("project_is_sharing_graphic_and_violent_content")}>The project is sharing graphic or violent content</ListGroup.Item>
          <ListGroup.Item role="button" onClick={() => handleReport("project_does_not_belong_to_user")}>The project does not belong to the posting user</ListGroup.Item>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseReportModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
  const deleteModal = (
    <Modal show={showDeleteModal} size="lg" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Project Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this project? Once it's deleted, it can't be brought back and you will lose its likes and comments.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Yes, delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const shareModal = (
    <Modal show={showShareModal} onHide={handleCloseShareModal}>
      <Modal.Header closeButton>
        <Modal.Title>Share this project &#128525;</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="copy-control">
        <input className="form-control refer-link" type="text" readOnly value={projectUrl} />
        <div className="button-container">
          <button onClick={handleUrlCopied} type="button" className="with-icon btn--icon border-0 btn btn-primary btn-sm">
            <div className="a-icon">
              <FaCopy />
            </div>
          </button>
        </div>
      </div>
      <div className="row mt-3 ml-1">
        <div className="col pr-0">
          <Button className="shareicons mb-2" variant="link">
            <FacebookShareButton url={projectUrl} className="btn btn-info">
              <FacebookIcon size={24} /> <span className="ml-2 p4">Share on Facebook</span>
            </FacebookShareButton>
          </Button>
          <Button className="shareicons mb-2" variant="link">
            <LinkedinShareButton url={projectUrl}>
              <LinkedinIcon size={24} /> <span className="ml-2 p4">Share on LinkedIn</span>
            </LinkedinShareButton>
          </Button>
        </div>
        <div className="col">
          <Button className="shareicons mb-2" variant="link">
            <TwitterShareButton url={projectUrl} className="btn btn-info">
              <TwitterIcon size={24} /> <span className="ml-2 p4">Share on Twitter</span>
            </TwitterShareButton>
          </Button>
          <Button className="shareicons mb-2" variant="link">
            <RedditShareButton url={projectUrl}>
              <RedditIcon size={24} /> <span className="ml-2 p4">Share on Reddit</span>
            </RedditShareButton>
          </Button>
        </div>
      </div>
      </Modal.Body>
    </Modal>
  )

  return (
    <>
      <Button variant="link" onClick={handleShowShareModal}>
        <FaShareAlt className="text-muted" size="20px"/>
      </Button>
      {maybeShareButton}

      {dropdown}
      {deleteModal}
      {reportModal}
      {shareModal}
      <Toast className="bg-success absolute-lower-left" onClose={() => setToastContents(null)} show={toastContents !== null} autohide delay={3000}>
        <Toast.Header className="display-block">
          <FaRegCheckCircle size={20}/> <span className="ml-2 mt-2 h6 align-middle"> {toastContents}</span>
        </Toast.Header>
      </Toast>
    </>
    );
}

export default ProjectDropdown;
