import {useEffect, useState} from "react";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/auth";
import { useParams, useLocation } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ReactLoading from "react-loading";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import { FaBriefcase, FaComment, FaPen, FaSave } from "react-icons/fa"; // https://react-icons.github.io/
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import ProjectList from './ProjectList';
import { User, Project } from "./types.d"
import Linkify from 'react-linkify';

function Profile() {
  let storedUser = localStorage.getItem('authUser');
  let defaultAuthUser: User|null = null;
  if (typeof storedUser === 'string') {
    defaultAuthUser = JSON.parse(storedUser);
  }
  // TODO: Setting currentUser to defaultAuthUser causes an infinite loop of fetching data in useEffect, not sure why
  const [currentUser,] = useState<User|null>(defaultAuthUser);
  let { username } = useParams<{ username: string }>();
  const locationState = useLocation() as any;
  // e.g. {hash: "", pathname: "/profile/@Rahul-Pandey781", search: "", state: {message: "Project deleted successfully"}}
  window.history.replaceState(null, ''); // Clear the state from location on refreshes
  const [user, setUser] = useState<User | null>(null);
  const [isUserMe, setIsUserMe] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [updatedBiography, setUpdatedBiography] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<Project[]>([]);
  // Number projects only counts public projects (not internal)
  const [numProjects, setNumProjects] = useState<number>(0);
  const [numComments, setNumComments] = useState<number>(0);

  useEffect(() => {
    const fetchUserProjects = async (usernameToFetch: string) => {
      let db = firebase.firestore();
      let projectsQuery = db.collection("projects").where("creator.username", "==", usernameToFetch).orderBy("createdTime", "desc");
      if (defaultAuthUser == null || !defaultAuthUser.isEmployee) {
        projectsQuery = projectsQuery.where("isInternal", "==", false);
      }
      await projectsQuery.onSnapshot(
        (projectsSnapshot) => {
          var numPublicProjects = 0;
          var projectsData: Project[] = [];
          projectsSnapshot.forEach((projectDoc) => {
            const singleProject = projectDoc.data() as Project;
            projectsData.push(singleProject);
            if (!singleProject.isInternal) {
              numPublicProjects += 1;
            }
          });
          console.log("projects", projectsData);
          setProjects(projectsData);
          setNumProjects(numPublicProjects);
          setIsLoading(false);
        }
        );
      }
      const fetchUserCommentCount = async (usernameToFetch: string) => {
        let db = firebase.firestore();
        const commentsSnapshot = await db.collection("project_comments").where("creator.username", "==", usernameToFetch).get();
        setNumComments(commentsSnapshot.size);
      }
      if (currentUser !== null) {
        setIsUserMe(username === currentUser.username);
        setUpdatedBiography(currentUser.biography);
      }
      fetchUser(username);
      fetchUserProjects(username);
      fetchUserCommentCount(username);
    }, [currentUser, username]);

    const fetchUser = async (usernameToFetch: string) => {
      let db = firebase.firestore();
      let query = await db.collection("users").where("username", "==", usernameToFetch).get();
      if (!query.empty) {
        const userDocument = query.docs[0].data();
        console.log("Profile info: ", userDocument);
        setUser(userDocument as User);
        firebase.analytics().logEvent('profile_loaded', {username: usernameToFetch});
      } else {
        console.log("no such user!!");
      }
      setIsLoading(false);
    }

    function editProfile() {
      console.log("updatedBiography", updatedBiography);
      setIsEditing(true);
    }

  async function saveProfile() {
    // firebase operation to update bio
    let db = firebase.firestore();
    await db.collection("users").doc(currentUser?.uid).update({'biography': updatedBiography})
    .then(() => {
      // could prevent this call if the bio hasn't changed
      console.log("updated the biography of the user");
      fetchUser(username);
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });
    setIsEditing(false);
  }

  var header = null;
  if (isLoading) {
    header = (<div className="d-flex loading-container">
      <ReactLoading type="spinningBubbles" className="m-auto" color="#9f9f9f"/>
    </div>);
  } else if (user) {
    let editButton = isUserMe ? <Button variant="info" onClick={editProfile}><FaPen /> Edit</Button> : <div />;
    var profileOptions;
    if (isEditing) {
      profileOptions = (<div>
          <InputGroup className="mb-1">
            <FormControl as="textarea" rows={7} aria-label="Biography textarea" value={updatedBiography || ''} onChange={(event) => {setUpdatedBiography(event.target.value)}}/>
          </InputGroup>
          <Button className="mt-2" block variant="info" onClick={saveProfile}><FaSave /> Save</Button>
        </div>);
    } else {
      profileOptions =
        (<div>
          <p className="respect-new-lines"><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}>{user.biography}</Linkify></p>
          {editButton}
        </div>);
    }
    let maybeEmployeeBadge = <span />;
    if (defaultAuthUser?.isEmployee && user.isEmployee) {
      maybeEmployeeBadge = (<span className="center-badge">
        <Badge pill variant="dark">  Employee</Badge>
      </span>);
    }
    header = (
      <div>
        <Container className="card p-3 mb-3 rounded">
          <Row>
            <Col md={4}>
            <div className="text-center">
              <Image src={user.photoUrl} roundedCircle className="project-logo-corner my-3" width={128} height={128} alt="Profile pic" />
              <h5><FaBriefcase /> {numProjects} {numProjects === 1 ? " project" : " projects"}</h5>
              <h5><FaComment /> {numComments} {numComments === 1 ? " comment" : " comments"}</h5>
            </div>
            </Col>
            <Col md={8}>
              <h2 className="line-height-compact">
                {user.displayName + ' '}
                {maybeEmployeeBadge}
              </h2>
              <h6>@{user.username}</h6>
              {isUserMe ? <div className="mb-3 mt-1 text-muted text-small small">{user.email}</div> : <div />}
              {profileOptions}
            </Col>
          </Row>
        </Container>
        <h2 className="mt-3">Projects</h2>
      </div>
    )
  } else {
    header = <p>We couldn't find anyone with username <b>{username}</b> </p>;
  }
  var projectList = ProjectList(projects, currentUser?.uid ?? null, true);
  const flashMessage = locationState?.state?.message;
  var flashMessageLayout = <div />;
  if (flashMessage) {
    flashMessageLayout = <Alert variant="info">{flashMessage}</Alert>;
  }
  return (
    <Container className="main-content">
      {flashMessageLayout}
      {header}
      {isLoading ? <div /> : projectList}
    </Container>);
}

export default Profile;
