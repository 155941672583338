import Image from 'react-bootstrap/Image';

function Footer() {

  return (
    <div className="text-center mt-2">
      <a className="mx-2" href="https://apps.apple.com/us/app/imber-side-project-community/id1573186472" target="_blank" rel="noreferrer"><Image width="150px" src="/get_app_store.png"/></a>
      <a className="mx-2" href="https://play.google.com/store/apps/details?id=com.imber.openhouse" target="_blank" rel="noreferrer"><Image width="150px" src="/get_google_play.png"/></a>
      <p className="my-2">Copyright © Imber · <a href="/about">About</a></p>
      <p><a href="https://github.com/Gear61/Imber-Documents/blob/main/Privacy%20Policy.md#privacy-notice" target="_blank" rel="noreferrer">Privacy Policy</a> · <a href="https://github.com/Gear61/Imber-Documents/blob/main/Terms%20and%20Conditions.md#terms-and-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a></p>
    </div>);
}

export default Footer;
